// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-post-index-tsx": () => import("./../src/templates/BlogPost/index.tsx" /* webpackChunkName: "component---src-templates-blog-post-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-drivers-index-tsx": () => import("./../src/pages/drivers/index.tsx" /* webpackChunkName: "component---src-pages-drivers-index-tsx" */),
  "component---src-pages-giftcards-index-tsx": () => import("./../src/pages/giftcards/index.tsx" /* webpackChunkName: "component---src-pages-giftcards-index-tsx" */),
  "component---src-pages-giftcards-refer-index-tsx": () => import("./../src/pages/giftcards/refer/index.tsx" /* webpackChunkName: "component---src-pages-giftcards-refer-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-menus-index-tsx": () => import("./../src/pages/menus/index.tsx" /* webpackChunkName: "component---src-pages-menus-index-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */)
}

